import { Component, OnInit,ViewChild, ViewContainerRef, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import { Calendar} from '@fullcalendar/core';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { defineFullCalendarElement } from '@fullcalendar/web-component';
import interactionPlugin from '@fullcalendar/interaction';
import { ApiService } from '../services/api.service';
import { Router,ActivatedRoute,NavigationExtras } from '@angular/router';
import {GlobalVariables} from '../services/local/globalVariables.service'
import {AddEventModalComponent} from '../add-event-modal/add-event-modal.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {AddNewEventsComponent} from './add-new-events/add-new-events.component';
import {UtilsService} from '../services/utils.service'
import { Subscriber } from 'src/app/shared/helpers/subscriber';
import { Subscription } from 'rxjs';
import esLocale from '@fullcalendar/core/locales/es'
import {parseDateTimeZ,transfromStringtoDateEN,getTimeNumber,transfromDateEN,transfromStringtoDate,parseDateTime,transfromDateTimeEN,hourMinStr,parseDateTimeT,parseDateToTime, parseDate, getMinutesNumber, 
        getTodayShortdateFormatEN, calculateNotBusinessHours,getDayNumber, getMonthNumber, getYearNumber,getNumberWeekDay} from '../shared/utilities/utilities'
import {DatePipe} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import  tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css';
import { ShowMessageComponent } from 'src/app/shared/modal/show_message/show-message.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { SearchEventsComponent } from '../shared/modal/search-events/search-events.component';
import 'moment/locale/es';
import { SeriesEventsComponent } from '../shared/modal/series-events/series-events.component';
import { BottomSheetComponent } from 'src/app/shared/notifications/bottom-sheet/bottom-sheet.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';









moment.locale("es");

//import { BASE_OPTION_REFINERS } from '@fullcalendar/core';
//(BASE_OPTION_REFINERS as any).schedulerLicenseKey = String



defineFullCalendarElement();
@Component({
  selector: 'app-resources-view-calendar',
  templateUrl: './resources-view-calendar.component.html',
  styleUrls: ['./resources-view-calendar.component.css']
})


export class ResourcesViewCalendarComponent extends Subscriber implements OnInit {
  @ViewChild('modal', { read: ViewContainerRef }) view: ViewContainerRef
  @ViewChild('popoverElementRef', { read: ViewContainerRef }) popoverElementRef: ViewContainerRef;
  @ViewChild('options', { read: ViewContainerRef }) menuoptionsRef: ViewContainerRef;
  @ViewChild('myDOMElement') MyDOMElement: ElementRef;
  @ViewChild('myDatePicker') MyDatePicker: ElementRef;
  @ViewChild ('matTrigger') matTrigger: MatMenuTrigger;
  @ViewChild ('matTrigger2') matTrigger2: MatMenuTrigger;
  @ViewChild('messages', { read: ViewContainerRef }) showContainerRef: ViewContainerRef;
  @ViewChild('series', {read:ViewContainerRef}) seriesContainerRef: ViewContainerRef;
  @Output() deleteMeEvent = new EventEmitter();
  @Output() closeMeEvent = new EventEmitter();
  @Output() submitModal = new EventEmitter();


  sub!: Subscription;
  modalRef= ViewContainerRef
  events: Array<any>=[]
  calendars: Array<any>=[]
  resources: Array<any>=[]
  colors: Array<any>=[]
  id:number
  calendarsNamesArr: Array<any>=[];
  namecalendarId:string;
  calendar: Calendar;
  dateTimeFrom: any;
  dateTimeTo: any;
  changeDate:any;
  dateDefault: any = new Date;
  day=null;
  todayWithPipe= null;
  pipe =new DatePipe('ES');
  name: string ='';
  collapse: boolean = false;
  mostrar: boolean= false;
  argsObject:Array<any>=[];
  isMoveTo: boolean=false;
  viewType:string='day';
  startWeek:any;
  endWeek: any;
  startMonth:any;
  endMonth:any;
  public positionx=0;
  public positiony=0;
  timePosition:any;
  rectbody: any;
  offsetX:any;
  offsetY:any
  minOffSetX:boolean;
  minOffSetY:boolean;
  startTimeWorkplace:any;
  endTimeWorkPlace: any;
  typeDelete: string='';
  typeUpdate: string ='';
  imagepath:string='';
  role: string='';
  userName: string;
  schedules: Array<any>=[];
  businessHours: Array<any>=[];
  notBusinessHours: Array<any>;
  isOpenCalendar: boolean=false



 
  
  constructor(
    public api: ApiService,
    public activatedRouter: ActivatedRoute,
    public router: Router,
    private globalVariables: GlobalVariables,
    public addEventModalComponent:AddEventModalComponent,
    public matDialog: MatDialog,
    private utilsService:UtilsService,
    private http: HttpClient,
    private bottomSheet: MatBottomSheet

  
   
    
  ) { 
      super();

      this.colors=this.globalVariables.colors  
      this.role=this.globalVariables.getRol()
      if (!this.role) this.role=localStorage.getItem('role')
    }

 
ngOnInit(): void {
  
  this.imagepath=this.globalVariables.getUrlImage()
  this.name= this.activatedRouter.snapshot.params.name;
  this.globalVariables.setfecha(this.dateDefault)
  this.id=this.activatedRouter.snapshot.params.id;
  this.globalVariables.setIdWorkPlace(this.activatedRouter.snapshot.params.id)

  
  this.getNotBusinessHours()

  if (localStorage.getItem("userName")) {
    this.userName= localStorage.getItem('userName')
  }

 
  let data={
    "dateTimeFrom": transfromStringtoDateEN(this.dateDefault),
    "dateTimeTo": transfromStringtoDateEN(this.dateDefault)
  }

  //this.api.getCalendarsByWorkPlaceByDay(this.id,data)
  this.api.service('/calendarsByWorkPlace/' + this.id,'post',data).subscribe(res=>{
    if( res ) {
      if (res.timeFromTo.timeFrom!=null && res.timeFromTo.timeTo!=null)
      {
        this.startTimeWorkplace= this.changeMinutesTimeFromToWorkplaces(res.timeFromTo.timeFrom, 'from' )
        this.endTimeWorkPlace=this.changeMinutesTimeFromToWorkplaces(res.timeFromTo.timeTo,'to' )
      }
      else{
        this.startTimeWorkplace= '08:00'
        this.endTimeWorkPlace='20:00'
      }

     
      this.globalVariables.setstartTimeWorkplace(this.startTimeWorkplace)
      res.calendars.forEach((calendar, i) => {
        this.calendars.push(calendar);
        const {idCalendar, name, shortName} = calendar;
        this.resources.push({
          id:idCalendar.toString(), 
          title: (name ?name :shortName), 
          eventColor:this.colors[i].color
        });

        calendar.calendarDetails.forEach((calendarDetail) => {
          this.events.push({
            id:calendarDetail.calendarDetail.idCalendarDetail, 
            resourceId: idCalendar, 
            start: calendarDetail.date + 'T' + calendarDetail.calendarDetail.timeFrom, 
            end: calendarDetail.date + 'T' + calendarDetail.calendarDetail.timeTo, 
            title: calendarDetail.calendarDetail.title,
            extendedProps: {
              description: calendarDetail.calendarDetail.description,
              date:calendarDetail.date,
              idUserCreate:calendarDetail.calendarDetail.idUserCreate,
              idUserLastUpdate:calendarDetail.calendarDetail.idUserLastUpdate,
              createdDate: calendarDetail.calendarDetail.createAt,
              updatedDate: calendarDetail.calendarDetail.updatedAt
            },
              calendars:calendarDetail.calendarDetail.calendars,
              dateFromModal:calendarDetail.calendarDetail.dateFrom + 'T' + calendarDetail.calendarDetail.timeFrom, 
              dateToModal: calendarDetail.calendarDetail.dateTo + 'T' + calendarDetail.calendarDetail.timeTo, 
              repeatParams: {repeat                :  calendarDetail.calendarDetail.repeat,
                             repeatAlways          :  calendarDetail.calendarDetail.repeatAlways,
                             repeatDuring          :  calendarDetail.calendarDetail.repeatDuring,
                             repeatEvery           :  calendarDetail.calendarDetail.repeatEvery,
                             repeatType            :  calendarDetail.calendarDetail.repeatType,
                             repeatUntil           :  calendarDetail.calendarDetail.repeatUntil,
                             repeatWeekdayPosition :  calendarDetail.calendarDetail.repeatWeekdayPosition,
                             repeatWeeklyFriday    :  calendarDetail.calendarDetail.repeatWeeklyFriday,
                             repeatWeeklyMonday    :  calendarDetail.calendarDetail.repeatWeeklyMonday,
                             repeatWeeklySaturday  :  calendarDetail.calendarDetail.repeatWeeklySaturday,
                             repeatWeeklySunday    :  calendarDetail.calendarDetail.repeatWeeklySunday,
                             repeatWeeklyThursday  :  calendarDetail.calendarDetail.repeatWeeklyThursday,
                             repeatWeeklyTuesday   :  calendarDetail.calendarDetail.repeatWeeklyTuesday,
                             repeatWeeklyWednesday :  calendarDetail.calendarDetail.repeatWeeklyWednesday
                            }
           })
        })
        
      })

      this.createArrCalendarsName()
      const calendarEl: HTMLElement = document.getElementById('calendar');
      this.calendar = new Calendar(calendarEl, {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin],
        //schedulerLicenseKey: 'XXX',
        schedulerLicenseKey:'CC-Attribution-NonCommercial-NoDerivatives',
        now: this.globalVariables.getfecha(),
        views: {
          resourceTimeGridDay: {
            titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday:'long' },
          },
        },
        slotMinTime:this.startTimeWorkplace, //hora incio del workplace
        slotMaxTime:this.endTimeWorkPlace, // hora fin del workplace
        //businessHours:[{startTime:'08:00:00', endTime:'13:00:00',daysOfWeek:[1,2,3,4,5]},{startTime:'14:00:00', endTime:'19:00:00',daysOfWeek:[1,2,3,4,5]}],
        businessHours: this.businessHours,
        nowIndicator: true,
        editable: true, // enable draggable events
        height: '90%',
        aspectRatio: 1.8,
        scrollTime: '06:00', // undo default 6am scrollTime
        headerToolbar: {
          left: 'collapse today prev,next search calendar',
          center: 'title',
          right: 'resourceTimeGridDay,timeGridWeek,dayGridMonth',
        },
        buttonText: {
          day: "Planificador"
        }, 
        locale: esLocale,
        initialView: 'resourceTimeGridDay',
        datesSet: this.handleDateChanged.bind(this) ,
        customButtons: this.getCustomOptions(),
        selectable: true,
        resources:this.resources,
        showNonCurrentDates:false,
        slotEventOverlap:false,
        events:this.events,
        /*events: [
          {
              startTime: '14:00:00',
              endTime: '15:00:00',
              background:'blue',
              id:'999',
              daysOfWeek: ['1','2','3','4','5']
              
          }],*/
        //events:[{id:'999',start:'2023-11-16T14:00:00',end:'2023-11-16T15:00:00',title:'comida'}],
      // dateClick: this.openModal.bind(this),
      /* eventClick: (info)=>{
          console.log("type", info.jsEvent.type);
          this.editEvent.bind(this)
        },*/
        eventClick: this.editEvent.bind(this),
        eventDrop: this.dropEvent.bind(this),
        eventResize: this.resizeEvent.bind(this),
        eventMouseEnter:this.eventDidMount.bind(this),
        eventDidMount: this.eventRightClick.bind(this),
        select: this.openModal.bind(this),

        }, );
      this.calendar.updateSize();
      this.calendar.render();
     
    }
  })
}


getNotBusinessHours(){
  
  var daysOfWeekArr: Array<any>=[]
  var schedulesCopy= this.schedules
  this.businessHours=[]

 //this.api.getWorkWeekActiveByWorkplace(this.id)
  this.api.service('/workweekActive/' + this.id,'get').subscribe(res=>{

      for (let i=0; i<res.workweekActive.length; i++)
      {
          this.schedules.push({id:res.workweekActive[i].idWeekday.idWeekday-1,idWeekday:res.workweekActive[i].idWeekday.idWeekday, from:hourMinStr(res.workweekActive[i].timeFrom,':'), to:hourMinStr(res.workweekActive[i].timeTo,':'),})
          if(i+1!=res.workweekActive.length){
            if(res.workweekActive[i].calendar.idCalendar!= res.workweekActive[i+1].calendar.idCalendar)
              i=res.workweekActive.length
          }
      }

      for (let i=0; i<this.schedules.length;i++)
      {
        daysOfWeekArr=[]
        var exist=false

        for (let j=0; j<schedulesCopy.length;j++)
         {  
            if (this.schedules[i].from==schedulesCopy[j].from  && this.schedules[i].to==schedulesCopy[j].to )
              {
                if (daysOfWeekArr.indexOf(schedulesCopy[j].id)==-1)
                  daysOfWeekArr.push(schedulesCopy[j].id)

                if (this.businessHours.length==0){
                        this.businessHours.push({startTime:this.schedules[i].from, endTime:this.schedules[i].to, daysOfWeek:daysOfWeekArr})
                    exist=true
                }
                    
                else {
                  for (let k=0; k<this.businessHours.length;k++){
                      const element = this.businessHours[k];
                      if(element.startTime == this.schedules[i].from &&  element.endTime== this.schedules[i].to)
                        { exist=true
                          this.businessHours[k].daysOfWeek=daysOfWeekArr}
                    }
                    if (!exist){
                          this.businessHours.push({startTime:this.schedules[i].from, endTime:this.schedules[i].to,daysOfWeek:daysOfWeekArr})
                    }
                  
                  }
              }
        }
      }   
  })
}


changeMinutesTimeFromToWorkplaces(time: any, typeRange: any){
  var timeResult: any
  switch  (typeRange){
    case 'from':
      if (getMinutesNumber(time)!='00:00')
       timeResult=getTimeNumber(time)+':00:00'
       
      else
        {timeResult=('0'+(Number(getTimeNumber(time))-1).toString() + ':00:00')
        }
    break;
    case 'to':
      if (getMinutesNumber(time)!='00:00'){
        timeResult=(Number(getTimeNumber(time))+1).toString() +':00:00'
      }
     
      else
        timeResult=(Number(getTimeNumber(time))+1).toString() + ':00:00'
    break;
  }
  

   return timeResult
}


openModal(arg) {

  this.mostrar=false // cerrar el menu del botón derecho
  document.getElementById('myDOMElement')
  var resourceId=''

  if(this.viewType!='week' && this.viewType!='month')
    { this.getCalendarIdName(arg.resource.id)
      resourceId=arg.resource.id
    }
  else
    {
      this.namecalendarId='',
      resourceId=''
    }
 

  const modalRef= this.utilsService.openModalAddNewEvent(AddNewEventsComponent,this.view,'', '',this.startTimeWorkplace, arg.dateStr?arg.dateStr:arg.startStr,arg.endStr?arg.endStr:'', this.namecalendarId, resourceId, this.calendarsNamesArr, arg.allDay,'',null,'','','' )
  modalRef.subscribe(res=>{
    
     if(res){
      var idCalendars: Array<any>
      switch(res["result"]){
        case "aceptar":
          { 
            for (let i=0; i<res.data.idCalendar.length; i++) {
                
                    this.calendar.addEvent({
                      id: res.data.idCalendarDetail,
                      resourceId:res.data.idCalendar[i],
                      title: res.data.title,
                      start: parseDateTimeZ(res.data.dateFrom),
                      end: parseDateTimeZ(res.data.dateTo),
                      extendedProps:{description:res.data.description},
                      repeatParams: res.data.repeatParams 
                    })
            }

            idCalendars=res.data.idCalendar
            
            switch (this.viewType)
            {
               case 'week':
                this.getEventsbyDay(transfromStringtoDateEN(this.startWeek),transfromStringtoDateEN(this.endWeek));
                break;
               case 'month':
                this.getEventsbyDay(transfromStringtoDateEN(this.startMonth),transfromStringtoDateEN(this.endMonth));
                break;
               case 'day':
                this.getEventsbyDay(parseDate(parseDateTimeZ(res.data.dateFrom)),parseDate(parseDateTimeZ(res.data.dateTo)))
                break;

            }

          /*   this._snackBar.open('Evento creado ' + res.data.title + ' ' + 'el' + ' ' + moment().format('ll'),'deshacer',{duration:5000}).onAction().subscribe(()=>{
               this.undoneActions('delete',{'id':res.data.idCalendarDetail, 'idCalendars':idCalendars})
             })*/
         
             this.openBottomSheet({'title': `<div> Evento creado <strong> ${res.data.title} </strong> el  ${moment(parseDateTime(res.data.dateFrom).toString()).format('ll')} </div>`,'type_actions':'delete', 'undone':true},{'id':res.data.idCalendarDetail, 'idCalendars':idCalendars},'',res.data.dateFrom,res.data.dateTo)
            
          break;
        }
        case "eliminar":
          {
            var event= this.calendar.getEventById(res.data)
            event.remove()
            document.getElementById('calendar')
            break;
          
          }
          case "Cancelar":
           break;
      
      }}
    }
  )

}

editEvent(arg){

  this.mostrar      = false // cerrar el menu del botón derecho
  document.getElementById('myDOMElement')
  var start         = parseDateTimeT(arg.event.startStr)
  var end           = parseDateTimeT(arg.event.endStr)

  var event         = this.calendar.getEventById(arg.event.id)
  var publicId      = event._def.publicId
  var resourceIds   : Array<any>=[]

  resourceIds       = this.createResourceIdsArr(publicId)
  var idCalendars   = this.getIdCalendars(publicId)

  const {dateFromModal, dateToModal, repeatParams,extendedProps} = this.events.filter(event=> event.id === parseInt(publicId))[0];
  var initialData: any={
    'idCalendars'           : idCalendars,
    'title'                 : event._def.title,
    'dateTimeFrom'          : parseDateTime(dateFromModal),
    'dateTimeTo'            : parseDateTime(dateToModal),
    'description'           : event._def.extendedProps.description,
    'repeat'                : repeatParams.repeat,
    'repeatDuring'          : repeatParams.repeatDuring,
    'repeatAlways'          : repeatParams.repeatAlways,
    'repeatEvery'           : repeatParams.repeatEvery,
    'repeatType'            : repeatParams.repeatType,
    'repeatUntil'           : repeatParams.repeatUntil,
    'repeatWeekdayPosition' : repeatParams.repeatWeekdayPosition,
    'repeatWeeklyFriday'    : repeatParams.repeatWeeklyFriday,
    'repeatWeeklyMonday'    : repeatParams.repeatWeeklyMonday,
    'repeatWeeklySaturday'  : repeatParams.repeatWeeklySaturday,
    'repeatWeeklySunday'    : repeatParams.repeatWeeklySunday,
    'repeatWeeklyThursday'  : repeatParams.repeatWeeklyThursday,
    'repeatWeeklyTuesday'   : repeatParams.repeatWeeklyTuesday,
    'repeatWeeklyWednesday' : repeatParams.repeatWeeklyWednesday

  }


  const modalRef= this.utilsService.openModalAddNewEvent(AddNewEventsComponent,this.view,event._def.title, event._def.extendedProps.description,this.startTimeWorkplace, dateFromModal, dateToModal, this.namecalendarId, event._def.resourceIds[0], this.calendarsNamesArr,arg.allDay, event._def.publicId, resourceIds, repeatParams, start, extendedProps,this.name)
  modalRef.subscribe(res=>{
    
     if(res){
      switch(res["result"]){
        case "aceptar":
          { 
            if(res.data)
            { 
              // this.removeExistentEvent(publicId)
                  if(publicId==res.data.idCalendarDetail) 
                  {  
                    /*for (let i=0; i< resourceIds.length; i++)
                    { var event= this.calendar.getEventById(res.data.idCalendarDetail)
                      if(event) event.remove()}
          
                    for (let i=0; i< res.data.idCalendar.length; i++)
                    {
                      this.calendar.addEvent(
                        {
                          id:res.data.idCalendarDetail,
                          resourceId:res.data.idCalendar[i].calendar.idCalendar, 
                          title: res.data.title,
                          start:res.data.dateFrom,
                          end: res.data.dateTo,
                          extendedProp: res.data.description
                        }
                      )


                    } */
              
                    switch (this.viewType)
                    {
                       case 'week':
                        this.getEventsbyDay(transfromStringtoDateEN(this.startWeek),transfromStringtoDateEN(this.endWeek));
                        break;
                       case 'month':
                        this.getEventsbyDay(transfromStringtoDateEN(this.startMonth),transfromStringtoDateEN(this.endMonth));
                        break;
                       case 'day':
                        this.getEventsbyDay(parseDate(start),parseDate(end))
                        break;
        
                    }

                    /* this._snackBar.open('Evento actualizado ' + res.data.title + ' ' + 'el' + ' ' + moment().format('ll'),'cerrar');*/
                    //this.openBottomSheet({'title':'Evento actualizado ' + res.data.title + ' ' + 'el' + ' ' + moment().format('ll'),'type_actions':'update'},initialData, publicId,start,end)
                   if (!repeatParams.repeat)
                    this.openBottomSheet({'title': `<div> Evento actualizado <strong> ${res.data.title} </strong>  el  ${moment(parseDateTime(dateFromModal).toString()).format('ll')} </div>`,'type_actions':'update', 'undone':true},initialData,publicId,start,end)
                   else
                    this.openBottomSheet({'title': `<div> Evento actualizado <strong> ${res.data.title} </strong>  el  ${moment(parseDateTime(dateFromModal).toString()).format('ll')} </div>`,'type_actions':'update', 'undone':false},initialData,publicId,start,end)
                  }
                  
            }
            break;
          }
        case "eliminar":
          {
            if (resourceIds)
            {
              for (let i=0; i< resourceIds.length; i++)
              { var event= this.calendar.getEventById(res.data)
                event.remove()}
            }
            document.getElementById('calendar')
            if (!repeatParams.repeat)
              this.openBottomSheet({'title': `<div> Evento eliminado <strong> ${event._def.title} </strong>  el  ${moment(parseDateTime(dateFromModal).toString()).format('ll')} </div>`,'type_actions':'reactivate', 'undone':true},initialData,publicId,start,end)
             else
              this.openBottomSheet({'title': `<div> Evento eliminado <strong> ${event._def.title} </strong>  el  ${moment(parseDateTime(dateFromModal).toString()).format('ll')} </div>`,'type_actions':'reactivate', 'undone':false},initialData,publicId,start,end)
            break;
          }
        
          default: break;
      }}
    })
}

removeExistentEvent(id: any){
  var j:Number=0
  this.events.forEach((event,i)=>{
    if(id==event.id && j!=this.events.length) 
    {
      this.events.splice(i,1)
      j=this.events.length
    }
  })
 
}


updateDropEvent(arg: any,data:any,resourceIds:Array<any>,publicId:any,start:any,end:any, initialData?: any){
 
  //this.api.updateEvent(arg.event._def.publicId,data)
  this.api.service('/calendarDetail/' + arg.event._def.publicId, 'put', data)
    .subscribe(res=>{
                     if(res.msg='OK')
                       {
                          for (let i=0; i< resourceIds.length; i++)
                            { var event= this.calendar.getEventById(publicId)
                               event.remove()}
                      
                          for (let i=0; i< resourceIds.length; i++)
                            {
                                this.calendar.addEvent(
                                  {
                                    id:publicId,
                                    resourceId:resourceIds[i],
                                    title: res.calendarDetail.title,
                                    start:start,
                                    end:end,
                                    //start:parseDateTimeZ(res.calendarDetail.dateFrom),
                                   // end: parseDateTimeZ(res.calendarDetail.dateTo),
                                    extendedProp: res.calendarDetail.description
                                  }
                                )
                            }

                            switch (this.viewType)
                            {
                               case 'week':
                                this.getEventsbyDay(transfromStringtoDateEN(this.startWeek),transfromStringtoDateEN(this.endWeek));
                                break;
                               case 'month':
                                this.getEventsbyDay(transfromStringtoDateEN(this.startMonth),transfromStringtoDateEN(this.endMonth));
                                break;
                               case 'day':
                                this.getEventsbyDay(parseDate(start),parseDate(end))
                                break;
                
                            }

                            if (data.repeat)
                              this.openBottomSheet({'title': `<div> Evento movido <strong> ${data.title} </strong> el  ${moment(parseDate(start)).format('ll')} </div>`,'type_actions':'update', 'undone':false},initialData,publicId,start,end)
                            else
                              this.openBottomSheet({'title': `<div> Evento movido <strong> ${data.title} </strong> el  ${moment(parseDate(start)).format('ll')} </div>`,'type_actions':'update', 'undone':true},initialData,publicId,start,end)
                              
                         }
                   }
                )
    

}


dropEvent(arg){

    var resourceIds         :  Array<any>=[]
    var resourceIdsOriginal :  Array<any>=[]
    var publicId            :  any
    publicId                =  arg.event._def.publicId
    var start               =  parseDateTimeT(arg.event.startStr)
    var end                 =  parseDateTimeT(arg.event.endStr)
    var oldStart            =  parseDate(parseDateTimeT(arg.oldEvent.startStr))
    
    const {dateFromModal, dateToModal, repeatParams} = this.events.filter(event=> event.id === parseInt(publicId))[0];

    if (this.viewType!='week' && this.viewType!='month')
    {
      this.dateTimeFrom       =  parseDate(dateFromModal)+' '+parseDateToTime(arg.event.startStr)
      this.dateTimeTo         =  parseDate(dateToModal)+' '+parseDateToTime(arg.event.endStr)
    }
    else{
      this.dateTimeFrom       =  parseDateTime(arg.event.startStr)
      this.dateTimeTo         =  parseDateTime(arg.event.endStr)
    }
  
    if( arg.newResource)
    {
      resourceIds           =  [arg.newResource._resource.id]
      resourceIdsOriginal   =  [arg.oldResource.id]
    }
      
    else
      resourceIds           =  this.createIdCalendarsArr(publicId)


    let data                =  {'idCalendars' : resourceIds,
                                'title'       : arg.event.title,
                                'dateTimeFrom': this.dateTimeFrom,
                                'dateTimeTo'  : this.dateTimeTo
                            }

    let initialData         =  {'idCalendars' : resourceIdsOriginal.length>0? resourceIdsOriginal: resourceIds,
                                'title'       : arg.event.title,
                                'dateTimeFrom': parseDate(dateFromModal)+' '+parseDateToTime(arg.oldEvent.startStr),
                                'dateTimeTo'  : parseDate(dateToModal)+' '+parseDateToTime(arg.oldEvent.endStr)}
                            
   
    if (repeatParams.repeat){ 
          var messageA: string = ''
          var messageB: string = ''
          var messageC: string = ''
          if((this.viewType=='week' || this.viewType=='month') && (oldStart!=parseDate(start)))
          {
            messageA = 'No se puede cambiar la fecha del evento'
            const modalRefmessage=this.utilsService.openModalShowMessage(ShowMessageComponent,this.showContainerRef,messageA,'serie')
            modalRefmessage.subscribe(res=>{
              switch (this.viewType)
              {
                 case 'week':
                  this.getEventsbyDay(transfromStringtoDateEN(this.startWeek),transfromStringtoDateEN(this.endWeek));
                  break;
                 case 'month':
                  this.getEventsbyDay(transfromStringtoDateEN(this.startMonth),transfromStringtoDateEN(this.endMonth));
                  break;
              
              }
            })
          }
          else{
            var arg  = arg
            var date = parseDate(parseDateTimeT(arg.event.startStr))
            messageA = 'Aplicar únicamente a este evento'
            messageB = 'Aplicar a todos los eventos de la serie.'
            messageC = 'Aplicar a este evento y a los siguientes.'
            this.updateSeriesEvent(arg,messageA, messageB, messageC,'drop',data,resourceIds,publicId,start,end, date, repeatParams)
          }
         }
    else
    this.updateDropEvent(arg,data,resourceIds,publicId,start,end, initialData)
     
                  
  }



resizeEvent(arg){ 
  

  var resourceIds   : Array<any>=[]
  var publicId      : any
  publicId          = arg.event._def.publicId
  resourceIds       = this.createIdCalendarsArr(publicId)
  var start         = parseDateTimeT(arg.event.startStr)
  var end           = parseDateTimeT(arg.event.endStr)


  const {dateFromModal, dateToModal, repeatParams} = this.events.filter(event=> event.id === parseInt(publicId))[0];

  this.dateTimeFrom= parseDate(dateFromModal)+' '+parseDateToTime(arg.event.startStr)
  this.dateTimeTo= parseDate(dateToModal)+' '+parseDateToTime(arg.event.endStr)
  let data          = {'idCalendars' : resourceIds,
                       'title'       : arg.event.title,
                       'dateTimeFrom': this.dateTimeFrom,
                       'dateTimeTo'  : this.dateTimeTo}

  let initialData   = {
                       'idCalendars' : resourceIds,
                       'title'       : arg.event.title,
                       'dateTimeFrom': parseDate(dateFromModal)+' '+parseDateToTime(arg.oldEvent.startStr),
                       'dateTimeTo'  : parseDate(dateToModal)+' '+parseDateToTime(arg.oldEvent.endStr)}
                       
  if (repeatParams.repeat)
  {
    const messageA: string='Aplicar únicamente a este evento'
    const messageB: string='Aplicar a todos los eventos de la serie.'
    const messageC: string='Aplicar a este evento y a los siguientes.'
    var arg               = arg
    var date              = parseDate(parseDateTimeT(arg.event.startStr))
    this.updateSeriesEvent(arg,messageA, messageB, messageC,'resize',data,resourceIds,publicId,start,end, date, repeatParams, initialData)
  }
  else
    this.updateResizeEvent(arg,data,resourceIds,publicId,start,end, initialData)

  
                    
}

updateResizeEvent(arg: any,data:any,resourceIds:Array<any>,publicId:any,start:any,end:any,initialData?:any){
  //  //this.api.updateEvent(arg.event._def.publicId,data)
  this.api.service('/calendarDetail/' + arg.event._def.publicId, 'put', data)
    .subscribe(res=>{
                       if(res.msg='OK')
                         {
                            for (let i=0; i< resourceIds.length; i++)
                            { var event= this.calendar.getEventById(publicId)
                              event.remove()}

                            for (let i=0; i< resourceIds.length; i++)
                            {
                              this.calendar.addEvent(
                                {
                                  id:publicId,
                                  resourceId:resourceIds[i],
                                  title: res.calendarDetail.title,
                                  start:start,
                                  end:end,
                                  extendedProp: res.calendarDetail.description
                                }
                              )
                            }

                            switch (this.viewType)
                            {
                               case 'week':
                                this.getEventsbyDay(transfromStringtoDateEN(this.startWeek),transfromStringtoDateEN(this.endWeek));
                                break;
                               case 'month':
                                this.getEventsbyDay(transfromStringtoDateEN(this.startMonth),transfromStringtoDateEN(this.endMonth));
                                break;
                               case 'day':
                                this.getEventsbyDay(parseDate(start),parseDate(end))
                                break;
                
                            }
           
                            if (data.repeat)
                              this.openBottomSheet({'title': `<div> Evento movido <strong> ${data.title} </strong> el  ${moment(parseDate(data.dateTimeFrom)).format('ll')}  </div>`,'type_actions':'update','undone':false},initialData,publicId,start,end)
                            else
                              this.openBottomSheet({'title': `<div> Evento movido <strong> ${data.title} </strong> el  ${moment(parseDate(data.dateTimeFrom)).format('ll')}  </div>`,'type_actions':'update','undone':true},initialData,publicId,start,end)
                          }
                      
                    })

}


createResourceIdsArr(idEvent:any):Array<any>{
  var j:number=0
  var resourceIds: Array<any>=[]

  this.events.forEach((i) => {
    
    if(i.id==idEvent && j!=this.events.length){
      i.calendars.forEach(calendars => {
        resourceIds.push({
          idCalendar: calendars.calendar.idCalendar,
          name:calendars.calendar.name?calendars.calendar.name:calendars.calendar.shortName
         }  
        )
      }); 
      
      j=this.events.length
    }   
  });

  return resourceIds
}

createIdCalendarsArr(idEvent:any):Array<any>{
  var j:number=0
  var resourceIds: Array<any>=[]
  this.events.forEach((i) => {
    
    if(i.id==idEvent && j!=this.events.length){
      i.calendars.forEach(calendars => {
        resourceIds.push(
          calendars.calendar.idCalendar,
        )
      }); 
      
      j=this.events.length
    }   
  });

  return resourceIds
}


eventRightClick(arg){
    arg.el.addEventListener("contextmenu",  event => {
    var argEvent=this.calendar.getEventById(arg.event.id)
    event.preventDefault();

    this.argsObject=[]
    this.argsObject.push({'idCalendarDetail':argEvent._def.publicId,'title':argEvent._def.title, 'idCalendar': argEvent._def.resourceIds[0],'dateTimeFrom':argEvent.startStr,'dateTimeTo':argEvent.endStr,'description':argEvent._def.extendedProps.description, 'calendars':this.createResourceIdsArr(arg.event.id)})
    this.mostrar=true
    this.positionx=event.x
    this.positiony=event.y
    this.timePosition=parseDateToTime(argEvent.startStr)
    this.timePosition= getTimeNumber(this.timePosition)

    document.getElementById('myDOMElement')
    return false;
  }, false);


}


eventDidMount(arg){

  this.getCalendarIdName(parseInt(arg.event._def.resourceIds[0]))

  const start= moment(arg.event.start).format("D MMMM YYYY")
  const timeStart= moment(arg.event.start).format("h:mm")
  const timeEnd= moment(arg.event.end).format("h:mm")



  tippy(arg.el, {
  theme: 'light',
  maxWidth: 650,
  content: '<div><strong>'+arg.event.title+'</strong></div>'+
  '<div> ' +start + ' ' + timeStart+' - ' +timeEnd+ '</div>'+
  '<div><strong> Calendario: </strong>' +this.namecalendarId+'</div>'+
  '<div><strong> Descripción: </strong> ' +arg.event.extendedProps.description+ '</div>',

  allowHTML: true,
  arrow:false,
  zIndex:99999999,
  duration:[0,null],


  onHidden(tippy){
    tippy.unmount()
    tippy.hide()
    tippy.destroy()
    tippy.clearDelayTimeouts()
    
  },

  });


  this.calendar.render()
}

eventDragStart(arg){  
  const isDragging= arg.event.id.setExtendedProp("_isDragging", true );

  if (isDragging != null && isDragging) {
    return;
  }
}

createEvent(id:any,resourceId:any,eventName:any,start: any, end: any) {
  let newEvent = {
    id:id,
    resourceId:resourceId,
    title: eventName,
    start:  start,
    end: end
  };
  this.events.push(newEvent);
}

changeWeek(flag, weekDay) {
  if (flag < 0) {
    var dateWeek:any
    const prevDate = weekDay.clone().subtract(1, "week");
    dateWeek= prevDate._d
  } else {
    const nextDate = weekDay.clone().add(1, "week");
    dateWeek= nextDate._d
  }
  return dateWeek
}

changeMonth(flag, monthDay) {
  if (flag < 0) {
    var dateMonth:any
    const prevDate = monthDay.clone().subtract(1, "month");
    dateMonth= prevDate._d
  } else {
    const nextDate = monthDay.clone().add(1, "month");
    dateMonth= nextDate._d
  }

  return dateMonth
}

getEventsBefore(arg){
  var dayWithPipe, monthWithPipe, yearWithPipe
  dayWithPipe= this.pipe.transform(this.calendar.getDate(), 'dd')
  monthWithPipe = this.pipe.transform(this.calendar.getDate(), 'MM')
  yearWithPipe = this.pipe.transform(this.calendar.getDate(), 'yyyy')

  if(this.viewType=='day'){
    if ((parseInt(dayWithPipe)-1)<10){
      var day: number= parseInt(dayWithPipe) -1
      dayWithPipe= '0' + day.toString()
    }
    else{
      var day: number= parseInt(dayWithPipe) -1
      dayWithPipe= day.toString()
    }
  }
  
  else if(this.viewType=='week')
  { 
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'yyyy')
    this.startWeek= moment.utc(`${yearWithPipe}/${monthWithPipe}/${dayWithPipe}`);
    this.startWeek=this.changeWeek(-1,this.startWeek)
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'yyyy')

  }
  
  else if(this.viewType=='month'){
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'yyyy')
    this.startMonth= moment.utc(`${yearWithPipe}/${monthWithPipe}/${dayWithPipe}`);
    this.startMonth=this.changeMonth(-1,this.startMonth)
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'yyyy')
    
  }
  
  const currentDate: any= yearWithPipe + '-' + monthWithPipe + '-' + dayWithPipe

  this.globalVariables.setfecha(currentDate)

  this.dateDefault=currentDate

  this.calendar.gotoDate(currentDate)
  
  this.calendar.render()
}

getEventsAfter(arg){
  var dayWithPipe, monthWithPipe, yearWithPipe

  if(this.viewType=='day'){
    dayWithPipe= this.pipe.transform(this.calendar.getDate(), 'd')
    monthWithPipe = this.pipe.transform(this.calendar.getDate(), 'MM')
    yearWithPipe = this.pipe.transform(this.calendar.getDate(), 'yyyy')

    if ((parseInt(dayWithPipe)+1)<10){
      var day: number= parseInt(dayWithPipe) +1
      dayWithPipe= '0' + day.toString()
    }
    else{
      var day: number= parseInt(dayWithPipe) +1
      dayWithPipe= day.toString()
    }
  }
  else if(this.viewType=='week')
  { 
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'yyyy')
    this.startWeek= moment.utc(`${yearWithPipe}/${monthWithPipe}/${dayWithPipe}`);
    this.startWeek=this.changeWeek(1,this.startWeek)
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startWeek), 'yyyy')

  }
  else if(this.viewType=='month'){
   
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'yyyy')
    this.startMonth= moment.utc(`${yearWithPipe}/${monthWithPipe}/${dayWithPipe}`);
    this.startMonth=this.changeMonth(1,this.startMonth)
    dayWithPipe= this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'dd')
    monthWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'MM')
    yearWithPipe = this.pipe.transform(transfromStringtoDateEN(this.startMonth), 'yyyy')
    
  }
  

  const currentDate: any= yearWithPipe + '-' + monthWithPipe + '-' + dayWithPipe

  this.globalVariables.setfecha(currentDate)

  this.dateDefault=currentDate

  this.calendar.gotoDate(currentDate)

  this.calendar.render()
}


collapsePanel(arg){
  this.collapse = !this.collapse;
  const divCalendarPicker: HTMLElement = document.getElementById('divCalendarPicker');
  const calendarPicker: HTMLElement = document.getElementById('calendarPicker');
  divCalendarPicker.style.width=(this.collapse ?'0px' :'270px');
  divCalendarPicker.style.margin=(this.collapse ?'0px' :'5px');
  divCalendarPicker.style.border=(this.collapse ?'3px solid white' :'0px');
  calendarPicker.hidden=this.collapse;

  this.calendar.setOption('customButtons', this.getCustomOptions());
}

getCustomOptions(){
  return  {
    collapse: {
      //icon: '<<<', 
      text: this.collapse ?'>>>' :'<<<', 
      click: this.collapsePanel.bind(this)
    }, 
    prev: {
      text: '<',
      click: this.getEventsBefore.bind(this)
    },
    next: {
      text: '>',
      click: this.getEventsAfter.bind(this)
    },
    search:{
      text: 'Buscar',
      click: this.goToSearchEvents.bind(this)
    },
   /* calendar:{
      text: !this.isOpenCalendar ?'Calendario':'Cerrar',
      click: this.openCalendar.bind(this)
    }*/
  }
}

handleDateChanged(arg){
  
  var currentDate= ''
  currentDate=getTodayShortdateFormatEN()

  if(currentDate==transfromStringtoDateEN(arg.start))
  {
    this.dateDefault=currentDate
    this.globalVariables.setfecha(currentDate)
  }
 


  if(arg.view.type=="resourceTimeGridDay"){
   // console.log(this.globalVariables.getstartTimeWorkplace, 'getstartTimeWorkplace')
    this.getEventsbyDay(transfromStringtoDateEN(arg.start),transfromStringtoDateEN(arg.start))
    //this.getNotBusinessHours()
    this.viewType='day';
    this.startWeek='';
    this.endWeek='';

  }
  if(arg.view.type=="timeGridWeek") {
       this.getEventsbyDay(transfromStringtoDateEN(arg.start),transfromStringtoDateEN(arg.end))
       //this.getNotBusinessHours()
       this.viewType='week'
       this.startWeek=arg.start;
       this.endWeek=arg.end
       this.dateDefault=this.startWeek
  }
    
  if(arg.view.type=="dayGridMonth")
  {
    this.getEventsbyDay(transfromStringtoDateEN(arg.start),transfromStringtoDateEN(arg.end))
    //this.getNotBusinessHours()
    this.viewType='month'
    this.startMonth=arg.start;
    this.endMonth=arg.end
    this.dateDefault=this.startMonth

  }


} 

createArrCalendarsName(){
    this.calendars.forEach(calendar => {
    const {idCalendar, name, shortName} = calendar;
    this.calendarsNamesArr.push({'name': (name?name :shortName), 'idCalendar': idCalendar}) 
  });

  return this.calendarsNamesArr;  
}

getCalendarIdName(id:number):string{
  const {name, shortName} = this.calendars.filter(calendar => calendar.idCalendar==id)[0];
  this.namecalendarId = (name?name :shortName);
  return this.namecalendarId;  
}

clickDateSelected(event){
  this.dateDefault=event
  this.globalVariables.setfecha(this.dateDefault)
  this.calendar.gotoDate(event)
  //this.getEventsbyDay(event,event)
  this.calendar.render()
  }

getEventsbyDay(dateFrom:any,dateTo:any ){
  this.events=[]
  let data={
    "dateTimeFrom": dateFrom,
    "dateTimeTo": dateTo
  }
    //this.api.getCalendarsByWorkPlaceByDay(this.id,data)
    this.api.service('/calendarsByWorkPlace/'+this.id,'post',data).subscribe(res=>{
      if( res ) {
        if (res.timeFromTo.timeFrom!=null && res.timeFromTo.timeTo!=null)
        {
          this.startTimeWorkplace= this.changeMinutesTimeFromToWorkplaces(res.timeFromTo.timeFrom, 'from' )
          this.endTimeWorkPlace=this.changeMinutesTimeFromToWorkplaces(res.timeFromTo.timeTo,'to' )
        }
        else{
          this.startTimeWorkplace= '8:00:00'
          this.endTimeWorkPlace='20:00:00'
        }
        res.calendars.forEach((calendar, i) => {
          this.calendars.push(calendar);
          
          const {idCalendar, name, shortName} = calendar;
          this.resources.push({
            id:idCalendar.toString(), 
            title: (name ?name :shortName), 
            eventColor:this.colors[i].color
          });
  
          calendar.calendarDetails.forEach((calendarDetail) => {
            this.events.push({
              id:calendarDetail.calendarDetail.idCalendarDetail, 
              resourceId: idCalendar, 
              start:calendarDetail.date + 'T' + calendarDetail.calendarDetail.timeFrom,
              end:calendarDetail.date + 'T' + calendarDetail.calendarDetail.timeTo,
              title: calendarDetail.calendarDetail.title,
              extendedProps: {
                description: calendarDetail.calendarDetail.description,
                date:calendarDetail.date,
                image:"assets/img/check.svg",
                idUserCreate:calendarDetail.calendarDetail.idUserCreate,
                idUserLastUpdate:calendarDetail.calendarDetail.idUserLastUpdate,
                createdDate: calendarDetail.calendarDetail.createAt,
                updatedDate: calendarDetail.calendarDetail.updatedAt 
              },
                calendars:calendarDetail.calendarDetail.calendars,
                dateFromModal:calendarDetail.calendarDetail.dateFrom + 'T' + calendarDetail.calendarDetail.timeFrom, 
                dateToModal: calendarDetail.calendarDetail.dateTo + 'T' + calendarDetail.calendarDetail.timeTo,
                repeatParams: {
                  repeat                :  calendarDetail.calendarDetail.repeat,
                  repeatAlways          :  calendarDetail.calendarDetail.repeatAlways,
                  repeatDuring          :  calendarDetail.calendarDetail.repeatDuring,
                  repeatEvery           :  calendarDetail.calendarDetail.repeatEvery,
                  repeatType            :  calendarDetail.calendarDetail.repeatType,
                  repeatUntil           :  calendarDetail.calendarDetail.repeatUntil,
                  repeatWeekdayPosition :  calendarDetail.calendarDetail.repeatWeekdayPosition,
                  repeatWeeklyFriday    :  calendarDetail.calendarDetail.repeatWeeklyFriday,
                  repeatWeeklyMonday    :  calendarDetail.calendarDetail.repeatWeeklyMonday,
                  repeatWeeklySaturday  :  calendarDetail.calendarDetail.repeatWeeklySaturday,
                  repeatWeeklySunday    :  calendarDetail.calendarDetail.repeatWeeklySunday,
                  repeatWeeklyThursday  :  calendarDetail.calendarDetail.repeatWeeklyThursday,
                  repeatWeeklyTuesday   :  calendarDetail.calendarDetail.repeatWeeklyTuesday,
                  repeatWeeklyWednesday :  calendarDetail.calendarDetail.repeatWeeklyWednesday
                 }  
            })
          })

        })

        this.calendar.removeAllEvents();
        this.calendar.addEventSource(this.events);
    
     }})
     
  }

ChangeDate(event){
    this.changeDate=event;
}

clickGoMain(event){
    this.mostrar=false // cerrar el menu del botón derecho
    document.getElementById('myDOMElement')
    event.preventDefault();
    this.router.navigate(['main']);
  }

  
clickExit(event){
    this.mostrar=false // cerrar el menu del botón derecho
    document.getElementById('myDOMElement')
    event.preventDefault();
    localStorage.clear()
    this.router.navigate(['login']);
   }
  

clickGoToSchedules(event){
  
    var arrIdCalendars: Array<any>=[]
    const queryParams: any = {};
    var exists: boolean=false
  
    this.calendars.forEach(calendar=>{
      let idCalendar= calendar.idCalendar
      exists=false

      if (arrIdCalendars.length==0){
        arrIdCalendars.push({'idCalendar':calendar.idCalendar, 'name':calendar.name, 'shortName':calendar.shortName, 'active': true})
      }
        
      else{  
        arrIdCalendars.forEach((arrIdCalendar, index)=>{
          if (!exists)
          {
            if (idCalendar==arrIdCalendar.idCalendar)
            {
              exists=true
            }
            
            else
              exists=false
            
            if(index== arrIdCalendars.length-1 && !exists)
            {
              arrIdCalendars.push({'idCalendar':calendar.idCalendar, 'name':calendar.name, 'shortName':calendar.shortName, 'active': true})

            }
          }
           
        })
      }
    })


    queryParams.myArray=JSON.stringify(arrIdCalendars);
    queryParams.name=this.name
    queryParams.title='Horarios'

    const navigationExtras: NavigationExtras = {
      queryParams
    };

    this.router.navigate(['panel/' + this.id + '/' +  'schedules/' + this.id ], navigationExtras)
  
}


closeMenu(){
    this.mostrar=false // cerrar el menu del botón derecho
    document.getElementById('myDOMElement')
 }

updateArrEvents(id:any, start: any, end: any):Array<any>{
    this.events.forEach(item=>{
      if (item.id== id)
      {
        item.start=start
        item.end=end
      }
    })
    return this.events
}

  
getEventsByDayByView(date: any,startMonth:any,startWeek:any,endMonth:any,endWeek:any){

    switch (this.viewType)
                  {
                     case 'week':
                      this.getEventsbyDay(transfromStringtoDateEN(startWeek),transfromStringtoDateEN(endWeek));
                      break;
                     case 'month':
                      this.getEventsbyDay(transfromStringtoDateEN(startMonth),transfromStringtoDateEN(endMonth));
                      break;
                      case 'day':
                        this.getEventsbyDay(parseDate(date),parseDate(date))
                      break;
                  
                  }

}

updateSeriesEvent(arg?:any, messageA?: string, messageB?:string, messageC?:any, type?: string, data?:any, resourceIds?:Array<any>,publicId?:any,start?:any, end?:any, date?: any, repeatParams?: any, initialData?:any)
  {  
    switch (type)
    {
      case 'move':
        const modalRefmessage=this.utilsService.openModalShowMessage(ShowMessageComponent,this.showContainerRef,messageA,'serie')
        modalRefmessage.subscribe
      break;
      case 'resize':
        const modalRefseries=this.utilsService.openModalSeriesEvents(SeriesEventsComponent,this.seriesContainerRef,'update-series',messageA,messageB,messageC)
          modalRefseries.subscribe(res=>{
            if(res)
            {
              switch(res["result"]){
                case "update-series":
                  switch(res["type"]){
                    case "allEvents":
                      this.typeUpdate='allEvents'
                      break;
                    case "onlyThisEvent":
                      this.typeUpdate='onlyThisEvent'
                      break;
                    case "thisEventAndLater":
                      this.typeUpdate='thisEventAndLater'
                      break;
                  }
                  break;
                case "cancel":
                  this.typeUpdate=''
                  this.getEventsByDayByView(date,this.startMonth,this.startWeek,this.endMonth,this.endWeek)
                break;
              }
              if (this.typeUpdate){
                data ={  'idCalendars' : data.idCalendars,
                         'title'       : data.title,
                         'dateTimeFrom': data.dateTimeFrom,
                         'dateTimeTo'  : data.dateTimeTo,
                         'option'      : this.typeUpdate,
                         'date'        : date,
                         'repeatType'  : repeatParams.repeatType,
                         'repeat'      : repeatParams.repeat,
                         'repeatAlways': repeatParams.repeatAlways,
                         'repeatEvery' : repeatParams.repeatEvery}
                this.updateResizeEvent(arg,data,resourceIds,publicId,start,end,initialData)
              }
           }
          })
      break;
      case "drop":
        const modalRefDropseries=this.utilsService.openModalSeriesEvents(SeriesEventsComponent,this.seriesContainerRef,'update-series',messageA,messageB,messageC)
        modalRefDropseries.subscribe(res=>{
          if(res)
            {
              switch(res["result"]){
                case "update-series":
                  switch(res["type"]){
                    case "allEvents":
                      this.typeUpdate='allEvents'
                      break;
                    case "onlyThisEvent":
                      this.typeUpdate='onlyThisEvent'
                      break;
                    case "thisEventAndLater":
                      this.typeUpdate='thisEventAndLater'
                      break;
                  }
                  break;
                case "cancel":
                  this.typeUpdate=''
                  this.getEventsByDayByView(date,this.startMonth,this.startWeek,this.endMonth,this.endWeek)
                break;
              }
              if (this.typeUpdate){
                data ={  'idCalendars' : data.idCalendars,
                         'title'       : data.title,
                         'dateTimeFrom': data.dateTimeFrom,
                         'dateTimeTo'  : data.dateTimeTo,
                         'option'      : this.typeUpdate,
                         'date'        : date,
                         'repeatType'  : repeatParams.repeatType,
                         'repeat'      : repeatParams.repeat,
                         'repeatAlways': repeatParams.repeatAlways,
                         'repeatEvery' : repeatParams.repeatEvery,
                         'repeatDuring': repeatParams.repeatDuring}
                this.updateDropEvent(arg,data,resourceIds,publicId,start,end)
              }
           }

        })
      break;

    } 
}

updateEvent(event?:any){
    var datos, initialData: any = {}
    var idCalendars             = this.getIdCalendars()
    var start                   = parseDate(this.argsObject[0].dateTimeFrom)
    var end                     = parseDate(this.argsObject[0].dateTimeTo)

    const {dateFromModal, dateToModal, repeatParams} = this.events.filter(event=> event.id === parseInt(this.argsObject[0].idCalendarDetail))[0];

    datos={
      'idCalendars' : idCalendars,
      'title'       : this.argsObject[0].title,
      'dateTimeFrom': event+ ' ' + parseDateToTime(this.argsObject[0].dateTimeFrom),
      'dateTimeTo'  : event+ ' ' + parseDateToTime(this.argsObject[0].dateTimeTo),
      'description' : this.argsObject[0].description,
      'repeatParams': repeatParams,
      'option'      : this.typeUpdate,
  
    }

    initialData   = {
      'idCalendars' : idCalendars,
      'title'       : this.argsObject[0].title,
      'dateTimeFrom': parseDate(this.argsObject[0].dateTimeFrom)+' '+parseDateToTime(this.argsObject[0].dateTimeFrom),
      'dateTimeTo'  : parseDate(this.argsObject[0].dateTimeTo)+' '+parseDateToTime(this.argsObject[0].dateTimeTo),
      'description' : this.argsObject[0].description}

    {//this.api.updateEvent(this.argsObject[0].idCalendarDetail,datos)
      this.api.service('/calendarDetail/' + this.argsObject[0].idCalendarDetail,'put',datos).subscribe(res=>{
      if(res.msg='OK')
        { 
          for (let i=0; i<idCalendars.length;i++){
            var event= this.calendar.getEventById(this.argsObject[0].idCalendarDetail)
                event.remove()
            }

             for (let i=0; i<idCalendars.length;i++){
              this.calendar.addEvent({
              id: this.argsObject[0].idCalendarDetail,
              resourceId: idCalendars[i],
              title: datos.title,
              start: datos.dateTimeFrom,
              end:  datos.dateTimeTo,
              extendedProp: datos.description
      
            })
          }

          if (repeatParams.repeat)
            this.openBottomSheet({'title':'Evento movido ' + datos.title + ' ' + 'el' + ' ' + moment(parseDate(datos.dateTimeFrom.toString())).format('ll'),'type_actions':'update','undone':false},initialData, this.argsObject[0].idCalendarDetail, start, end)  
          else
            this.openBottomSheet({'title':'Evento movido ' + datos.title + ' ' + 'el' + ' ' + moment(parseDate(datos.dateTimeFrom.toString())).format('ll'),'type_actions':'update','undone':true},initialData, this.argsObject[0].idCalendarDetail, start, end)
          }
        else 
        { console.log('error modificando evento') }
      })}

      
      this.mostrar=false // cerrar el menu del botón derecho
      document.getElementById('myDOMElement')
      //document.getElementById('calendar')
      this.calendar.render()  
}

updateDateEvent(event){
    var dateEvent=event

    if(this.existsRepeatParams(this.argsObject[0].idCalendarDetail))
    {
       const messageA= 'No se puede cambiar la fecha del evento'
       this.updateSeriesEvent(dateEvent, messageA,'','','move')
    }
  
    else
     this.updateEvent(dateEvent)
}
  

getIdCalendars(idEvent?:any): Array<any>{
    var idCalendars: Array<any>=[]
    var j:number=0
    if (!idEvent){
      for (let i=0; i<this.argsObject[0].calendars.length; i++ )
      {
        idCalendars.push(this.argsObject[0].calendars[i].idCalendar)
      }
  
    }

    else
     {
      this.events.forEach((i) => {
        if(i.id==idEvent && j!=this.events.length){
          i.calendars.forEach(calendars => {
            idCalendars.push(
              calendars.calendar.idCalendar 
            )
          }); 
          
          j=this.events.length
        }   
      });
     }
   
    return idCalendars
}



existsRepeatParams(id: any){
    var repeat: boolean=false
      this.events.forEach(item=>{
        if(item.id==id){
          if( item.repeatParams.repeat==true)
            repeat= true
          else
            repeat= false
        }   
      })
      return repeat;
}


deleteSeriesEvent(idCalendars: Array<any>,val?:boolean){ 
    const modalRefseries=this.utilsService.openModalSeriesEvents(SeriesEventsComponent,this.seriesContainerRef,'delete-series','Aplicar únicamente a este evento','Aplicar a todos los eventos de la serie.','Aplicar a este evento y a los siguientes.')
    modalRefseries.subscribe(res=>{
      if(res)
      { switch(res["result"]){
        case "delete-series":
          switch(res["type"]){
            case "allEvents":
              this.typeDelete='allEvents'
              break;
            case "onlyThisEvent":
              this.typeDelete='onlyThisEvent'
              break;
            case "thisEventAndLater":
              this.typeDelete='thisEventAndLater'
              break;
          }
          break;
        case "cancel":
          this.typeDelete=''
         break;
      }}
      if (this.typeDelete!='')
        {
          var data={
              option: this.typeDelete,
              date  : parseDate(this.argsObject[0].dateTimeFrom)
          }
          //this.api.deleteSeriesEvent(this.argsObject[0].idCalendarDetail,data)
          this.api.service('/calendarDetail/' + this.argsObject[0].idCalendarDetail,'delete',data)
          .subscribe(res=>{
            if(res.msg='OK')
                    { 
                        if(val)
                        {
                          this.globalVariables.setresouserselected(0)
                        } 

                    }
                      else 
                      {
                      /*console.log('error eliminando evento ')*/ }
                      for(let i=0; i<idCalendars.length;i++)
                         {
                          var event= this.calendar.getEventById(this.argsObject[0].idCalendarDetail)
                          event.remove()
                         }
                      this.deleteMeEvent.emit({ result: "eliminar", data: this.argsObject[0].idCalendarDetail})
                      
                }
            )
        }
   
    })
}

deleteEvent(val?: boolean){

    var initialData: any = {}
    var idCalendars             = this.getIdCalendars()
    var start                   = parseDate(this.argsObject[0].dateTimeFrom)
    var end                     = parseDate(this.argsObject[0].dateTimeTo)

    initialData   = {
      'idCalendars' : idCalendars,
      'title'       : this.argsObject[0].title,
      'dateTimeFrom': parseDate(this.argsObject[0].dateTimeFrom)+' '+parseDateToTime(this.argsObject[0].dateTimeFrom),
      'dateTimeTo'  : parseDate(this.argsObject[0].dateTimeTo)+' '+parseDateToTime(this.argsObject[0].dateTimeTo),
      'description' : this.argsObject[0].description}
    
      if(this.existsRepeatParams(this.argsObject[0].idCalendarDetail))
    {
      this.deleteSeriesEvent(idCalendars, val)
    }
    else
    {
      const modalRefmessage=this.utilsService.openModalShowMessage(ShowMessageComponent,this.showContainerRef,'¿Estas seguro que desea eliminar el evento?','delete')
      modalRefmessage.subscribe(res=>{
      if(res){
        switch(res["result"]){
          case "eliminar":
            { 
              //this.api.deleteEvent(this.argsObject[0].idCalendarDetail)
              this.api.service('/calendarDetail/'+this.argsObject[0].idCalendarDetail,'delete').subscribe(res=>{
                if(res.msg='OK')
                         { 
                          if(val)
                            this.globalVariables.setresouserselected(0)
                          }
                         
                          for(let i=0; i<idCalendars.length;i++)
                           {
                            var event= this.calendar.getEventById(this.argsObject[0].idCalendarDetail)
                            event.remove()
                           }
                          this.deleteMeEvent.emit({ result: "eliminar", data: this.argsObject[0].idCalendarDetail})
                          this.openBottomSheet({'title': `<div> Evento eliminado <strong> ${this.argsObject[0].title} </strong> el  ${moment(parseDateTime(this.argsObject[0].dateTimeFrom).toString()).format('ll')} </div>`,'type_actions':'reactivate', 'undone':true},initialData,this.argsObject[0].idCalendarDetail,start,end)
                          
                      
                        }
              )
              break;
            }
          case "cancelar":
           {
            this.closeMeEvent.emit()
            break;
           }
          }}})
    }
   
      this.mostrar=false // cerrar el menu del botón derecho
      document.getElementById('myDOMElement')

}

copyEvent(event){
    var datos, initialData: any  ={}
    var idCalendars =this.getIdCalendars()

 
    datos={
      'idCalendars' :idCalendars,
      'title'       :this.argsObject[0].title,
      'dateTimeFrom':event+ ' ' + parseDateToTime(this.argsObject[0].dateTimeFrom),
      'dateTimeTo'  :event+ ' ' + parseDateToTime(this.argsObject[0].dateTimeTo),
      'description' :this.argsObject[0].description
    }

  //this.api.addNewEvent(datos)
    this.api.service('/addCalendarDetail/','post',datos).subscribe(res=>{
      if(res.msg='OK'){
       for(let i=0; i<res.calendars.length;i++){
        this.calendar.addEvent({
          id: res.calendarDetail.idCalendarDetail,
          resourceId:res.calendars[i].idCalendar,
          title: res.calendarDetail.title,
          start: parseDateTimeZ(res.calendarDetail.dateFrom),
          end: parseDateTimeZ(res.calendarDetail.dateTo),
          extendedProps:{description:res.calendarDetail.description}
        })
       }
       this.openBottomSheet({'title':'Evento copiado ' + datos.title + ' ' + 'el' + ' ' + moment(event.toString()).format('ll'),'type_actions':'delete', 'undone':true},{'id':res.calendarDetail.idCalendarDetail, 'idCalendars':idCalendars})  
      }
    
    })
    this.mostrar=false // cerrar el menu del botón derecho
    document.getElementById('myDOMElement')
  }



undoneAddEvent(idCalendarDetail:any, idCalendars:Array<any>, start: any, end: any){
  //this.api.deleteEvent(idCalendarDetail)
    this.api.service('/calendarDetail/' + idCalendarDetail,'delete').subscribe(res=>{
      if(res.msg='OK')
          { 
              for(let i=0; i<idCalendars.length;i++)
                {
                    var event= this.calendar.getEventById(idCalendarDetail)
                    event.remove()
                }
              this.deleteMeEvent.emit({ result: "eliminar", data: idCalendarDetail})
               if(start && end)
                this.updateViews(start,end)
            
            }           
    })
    
}


undoneUpdateEvent(idDetail:any, data: any, start:any, end:any)
  {
    //this.api.updateEvent(idDetail,data)
    this.api.service('/calendarDetail/' + idDetail, 'put', data)
      .subscribe(res=>{
        if(res.msg ='OK')
          { 
            if(start && end)
              this.updateViews(start,end)
          }
        else 
            { console.log('error modificando evento') }
        })

}

undoneDeleteEvent(idDetail:any, data:any,start:any, end:any){
  //this.api.reactivateEvent(idDetail,data)
  this.api.service('/calendarDetail/reactivate/' +idDetail,'put',data)
    .subscribe(res=>{
      if(res.msg ='OK')
      { 
        if(start && end)
          this.updateViews(start,end)
      }
    else 
        { console.log('error reactivando evento') }
    })

}

undoneActions(type: any, data: any, idDetail?:any, start?:any, end?:any){

    switch (type){
      case 'delete':
        this.undoneAddEvent(data.id, data.idCalendars, start, end)
        break;
      case 'add':
        break;
      case 'update':
        this.undoneUpdateEvent(idDetail,data, start, end)
        break;
      case 'reactivate':
        this.undoneDeleteEvent(idDetail,data, start, end)
        break;
      case 'close':
        break;
    }

}

updateViews(start:any,end:any)
  {
    switch (this.viewType)
    {
       case 'week':
        this.getEventsbyDay(transfromStringtoDateEN(this.startWeek),transfromStringtoDateEN(this.endWeek));
        break;
       case 'month':
        this.getEventsbyDay(transfromStringtoDateEN(this.startMonth),transfromStringtoDateEN(this.endMonth));
        break;
       case 'day':
        this.getEventsbyDay(parseDate(start),parseDate(end))
        break;

    }

}

   
getPositionX():string{
    var x: any
    this.rectbody=document.getElementById('calendar').getBoundingClientRect()
    this.offsetX = this.rectbody.right - this.positionx

    
    if (this.rectbody.width >=462 && this.rectbody.width<501)
    {
          if(this.offsetX<120){
            x=this.rectbody.right-130;
            this.minOffSetX=true
          }
          else{
            x=this.positionx
            if(this.offsetX<280)
             this.minOffSetX=true
            else
             this.minOffSetX=false
          }
     }

       
    if (this.rectbody.width >=501 && this.rectbody.width<600)
    {
         if(this.offsetX<120){
           x=this.rectbody.right-140;
           this.minOffSetX=true
         }
         else{
           x=this.positionx
           if(this.offsetX<280)
             this.minOffSetX=true
           else
             this.minOffSetX=false
         }
    }

    if (this.rectbody.width >=600 && this.rectbody.width<1200)
    {
        if(this.offsetX<70){
            x=this.rectbody.right-140;
            this.minOffSetX=true
        }
        else{
            x=this.positionx
            if(this.offsetX<423)
             this.minOffSetX=true
            else
             this.minOffSetX=false
        }
    }


    if (this.rectbody.width>=1200 && this.rectbody.width<1400){
        if (this.offsetX<305){
          x=this.rectbody.right-190;
          this.minOffSetX=true
        }
    
        else{
          x=this.positionx
          this.minOffSetX=false
        }
     
     }


     if (this.rectbody.width>=1400){
        if (this.offsetX<305){
             x=this.rectbody.right-190;
             this.minOffSetX=true
           }
    
           else{
              x=this.positionx
              this.minOffSetX=false
            }
     
     }


    if (this.rectbody.width<462){
        if(this.offsetX<100){
            x=this.rectbody.right-130;
            this.minOffSetX=true
          }
         
          else{
            x=this.positionx
            if(this.offsetX<360)
              this.minOffSetX=true
            else
              this.minOffSetX=false
          }   
    }

   // console.log(this.minOffSetX,'minOffSetX')
     
    return x.toString() + 'px'
   
  
}

getPositionY():string{
    var y: any
    this.rectbody = document.getElementById('calendar').getBoundingClientRect()
    this.offsetY  = this.rectbody.bottom - this.positiony
    if(this.offsetY<280)
     this.minOffSetY=true
    else
     this.minOffSetY=false
    
     y = this.positiony
    return y.toString() + 'px'
}

  
setMostrarFlag(){
    this.mostrar=false
    document.getElementById('myDOMElement')

}

resetFlagMenuTrigger(){
    this.matTrigger.closeMenu()
    this.matTrigger2.closeMenu()
}

openCalendar(){
  this.isOpenCalendar= !this.isOpenCalendar
  this.calendar.setOption('customButtons', this.getCustomOptions());
 
}

goToSearchEvents(){
      const modalRefmessage=this.utilsService.openModalSearchEvents(SearchEventsComponent,this.showContainerRef, this.id, this.view)
      modalRefmessage.subscribe(res=>{
      if(res){
          switch(res["result"]){
            case "gotoDate":
              this.calendar.gotoDate(transfromDateEN(res.datefrom))
              break;
            case "editEvent":
                    { 
                      var publicId= res.idCalendarDetail
                      if(res.data)
                      { 
                         this.removeExistentEvent(publicId)
                            if(publicId==res.data.idCalendarDetail) 
                            {  
                              for (let i=0; i< res.resourceIds.length; i++)
                              { var event= this.calendar.getEventById(res.data.idCalendarDetail)
                                if(event) event.remove()}
                                
                              for (let i=0; i< res.data.idCalendar.length; i++)
                              {
                                this.calendar.addEvent(
                                  {
                                    id:res.data.idCalendarDetail,
                                    resourceId:res.data.idCalendar[i].calendar.idCalendar, 
                                    title: res.data.title,
                                    start:res.data.dateFrom,
                                    end: res.data.dateTo,
                                    extendedProp: res.data.description
                                  }
                                )
         
                              } 
          
                              switch (this.viewType)
                              {
                                 case 'week':
                                  this.getEventsbyDay(transfromStringtoDateEN(this.startWeek),transfromStringtoDateEN(this.endWeek));
                                  break;
                                 case 'month':
                                  this.getEventsbyDay(transfromStringtoDateEN(this.startMonth),transfromStringtoDateEN(this.endMonth));
                                  break;
                                 case 'day':
                                  this.getEventsbyDay(parseDate(res.data.dateFrom),parseDate(res.data.dateTo))
                                  break;
                  
                              }
  
                            }
                      }
                    }
              break;
            case "cancelar":
              break;
            }}})
    }

openBottomSheet(data:any, data_event: any, idDetail?:any,start?:any,end?:any) {
      let sheetRef =  this.bottomSheet.open(BottomSheetComponent, {
        data: data
      });
      sheetRef.afterDismissed().subscribe( data => {
         if(data && data.message=='undone') {
          this.undoneActions(data.type,data_event, idDetail,start,end)
        }
      });
  }

     
openAsideMenu(event){
   document.getElementById("sideNavigation").style.display="flex";
   document.getElementById("close").style.display = "flex";
   document.getElementById("backdrop").style.display = "flex";
   
  }

closeNav(event){
    event.stopPropagation();
    event.preventDefault();
    document.getElementById("sideNavigation").style.display = "none";
    document.getElementById("close").style.display = "none";
    document.getElementById("backdrop").style.display = "none";
  
  }
}

