import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { GlobalVariables } from './services/local/globalVariables.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  urlimage: any
  hostname:any
  host: any
  path_image:any
  //private config: {version: string}; 
  config: any = {}

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,  private http: HttpClient, private configService: ConfigService, private globalVariables: GlobalVariables) {
    //this.hostname='confortauto'
   //this.host="1cal.solucionait.es"
   // this.host="1cal.confortauto.com"
    
    //this.hostname=this.host.replace(/\.com|\.es|\.net|\.it|\.de|\.fr|\.pt|\.us|\.co.uk|\.uk|\.org/g,'').replace(/\./g,'-')
 
    this.hostname = document.location.hostname.replace(/\.com|\.es|\.net|\.it|\.de|\.fr|\.pt|\.us|\.co.uk|\.uk|\.org/g,'').replace(/\./g,'-')

    //console.log(this.hostname,'hostname prueba')

   /* if(localStorage.getItem("urlimage")){
      if(localStorage.getItem("name")==this.hostname)
       this.urlimage=localStorage.getItem("urlimage")
       else{
          this.configService.configuracion.urlimages.forEach((element, index) => {
            if (element.name==this.hostname)
            {
              localStorage.setItem("urlimage",element.urlimage)
              localStorage.setItem("name", element.name)
            }
      
                
          });
          this.urlimage=localStorage.getItem("urlimage")
        }

    
    }*/
    
   /* else{
    
      this.configService.configuracion.urlimages.forEach(element => {
        if (element.name==this.hostname)
        {
        
          localStorage.setItem("urlimage",element.urlimage)
          localStorage.setItem("name", element.name)
          this.globalVariables.setUrlImage(element.urlimage)
          this.globalVariables.setHostname(element.name)
        }

      
          
        
      });

      this.urlimage=localStorage.getItem("urlimage")
      //this.matIconRegistry.addSvgIcon("logo", this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.urlimage}`));

    }*/

    if (this.globalVariables.getUrlImage())
    {
      if (this.globalVariables.getHostname()==this.hostname)
      this.urlimage=this.globalVariables.getHostname()
      else{
        var found:boolean
          this.configService.configuracion.urlimages.forEach((element, index) => {
            if (element.name==this.hostname && !found)
            {
              this.globalVariables.setUrlImage(element.urlimage)
              this.globalVariables.setHostname(element.name)
              found=true
            }
            else if (element.name!=this.hostname && index==this.configService.configuracion.urlimages.length-1 && !found)
            {
              this.globalVariables.setUrlImage('"localhost/media/iconos')
              this.globalVariables.setHostname('localhost')
              this.hostname='localhost'
            }
          })
      }
    }
    else
    {
      var found:boolean
      this.configService.configuracion.urlimages.forEach((element, index) => {
     
        if (element.name==this.hostname && !found)
        {
          this.globalVariables.setUrlImage(element.urlimage)
          this.globalVariables.setHostname(element.name)
          found=true

        }
        else if (element.name!=this.hostname && index==this.configService.configuracion.urlimages.length-1 && !found )
        {
          this.globalVariables.setUrlImage('localhost/media/iconos')
          this.globalVariables.setHostname('localhost')
          this.hostname='localhost'
        }
      })
    }
    
  }

  ngOnInit(): void {

  
    //let hostname = document.location.hostname.replace(/\.com|\.es|\.net|\.it|\.de|\.fr|\.pt|\.us|\.co.uk|\.uk|\.org/g,'').replace(/\./g,'-')

   // this.config= this.globalVariables.config

   /* const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');*/
      
    /*this.http
      .get<{ version: string }>("/assets/json/config.json", {headers})
      .subscribe(config => {
        console.log(config,'config del servidor');
        if (config.version !== this.config.version) {
          location.reload(); 
        }
      });*/

    let themeLink = document.getElementById('client-theme') as HTMLLinkElement;

    themeLink.href = 'assets/custom/'.concat(this.hostname,'/styles.css');

    let favIcon = document.querySelector('#appIcon') as HTMLLinkElement;

    favIcon.href = 'assets/custom/'.concat(this.hostname,'/media/iconos/favicon/favicon.ico') 


    let favIconPng = document.querySelector('#appIconPng') as HTMLLinkElement;

    favIconPng.href = 'assets/custom/'.concat(this.hostname,'/media/iconos/favicon/favicon.png') 

    
    this.matIconRegistry.addSvgIcon(
      "move-to",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/move-to.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "copy-to",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/copy-to.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/delete.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "accept",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/comprobado.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "remove",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/trash-can-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icon-search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "workshop",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icon-workshop-24.svg")
    )
  
  }





}


