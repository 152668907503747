<div class="modal" >
    
   <!--<div class="eventeditor" cdkDrag>-->
    <div class="eventeditor">
            <a (click)='closeEvent($event)' class="button ghost close-icon">
                <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
            </a>
            <div>
                <h2>Buscar evento</h2>
                <div class="dates">
                 <div class="content-flex">
                    <label>Desde</label>
                    <div class="datepicker">
                        <input name="dateFrom" tabindex="2" type="hidden" class="flatpickr-input">
                        <input id="select-dateFrom" (click)="setActive(true,false)" class="input flatpickr-input" placeholder= {{this.dateFrom}} tabindex="2" type="text">
                        <div *ngIf="isActiveFrom" class="active" id="dateFrom-picker" >
                            <app-date-picker (dateSelected)="clickDateSelected($event)"></app-date-picker>
                        </div>
                    </div>
                 </div>
                   
                  <div class="content-flex">
                    <label>Hasta</label>
                    <div class="datepicker">
                        <input  name="dateTo" tabindex="4" type="hidden" class="flatpickr-input dateto" >
                        <input  id="select-dateTo"  (click)="setActive(false, true)" class="input flatpickr-input" placeholder= {{this.dateTo}} tabindex="4" type="text">
                        <div *ngIf="isActiveTo" class="active" id="dateTo-picker">
                            <app-date-picker (dateSelected)="clickDateSelected($event)"></app-date-picker>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="search-event">
                    <div class="content-search">
                        <input class="form-control" type="text"  [(ngModel)]="searchText" name="search" autocomplete="off" placeholder="Buscar por nombre, matricula" (keyup.enter)="onKey(searchText)">
                        <button class="button button-search" (click)="searchEvent($event)" type="submit">Buscar</button>
                        <a class="link clear" href="#" (click)="clearEvents($event)">
                            <span class="text-link">Limpiar</span>
                        </a>
                    </div>
                </div>
                <div class="table-results">
                    <div  class="header">
                       <div class="cell cell-1 cell-1-header">Fecha</div>
                       <div class="cell cell-1 cell-1-header">Hora</div>
                       <div class="cell cell-2">Descripción</div>
                       <div class="cell cell-4 cell-4-header">Acciones</div>
                    </div>
                    <div class="body">
                        <div *ngIf="loading" style="display:flex; justify-content: center; align-items: center; width:inherit" >
                            <mat-spinner [diameter]="25"></mat-spinner>
                        </div>
                        <div *ngIf="events.length==0 && !loading && !error" class="text-no-found">
                            <p>{{msg}}</p>
                        </div>
                        <div *ngFor="let item of events" class="row" style="width:100%">
                            <div class="cell cell-1">
                                <div class="title">Fecha</div>
                                <div>{{item.dateFrom}}</div>
                            </div>
                            <div class="cell cell-1">
                                <div class="title">Hora</div>
                                 <div >{{item.timeFrom}}</div>
                             </div>
                            <div class="cell cell-2">
                               <div class="title">Descripción</div>
                                <div >{{item.title}}</div>
                            </div>
                            <div class="cell cell-4">
                                <div class="title">Acciones</div>
                                <div>
                                    <a class="link" href="#" (click)="goToModalEditEvent($event, item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="18px" height="18px"  fill-rule="nonzero"><g fill="#6c6e79" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(10.66667,10.66667)"><path d="M19.17188,2c-0.72375,0 -1.4475,0.27562 -2,0.82813l-1.17187,1.17188l4,4l1.17188,-1.17187c1.104,-1.104 1.104,-2.895 0,-4c-0.5525,-0.5525 -1.27625,-0.82812 -2,-0.82812zM14.5,5.5l-11.5,11.5v4h4l11.5,-11.5z" ></path></g></g></svg>
                                        <span class="text-link">Modificar</span>
                                    </a>
                                </div>
                                <div class="actions">
                                    <a class="link" href="#" (click)="goToCalendar($event, item.dateFrom)">
                                        <svg width="18px" height="18px"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top:3px">
                                            <g id="Calendar / Calendar">
                                            <path id="Vector" d="M4 8H20M4 8V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H16.8031C17.921 20 18.48 20 18.9074 19.7822C19.2837 19.5905 19.5905 19.2842 19.7822 18.9079C20 18.4805 20 17.9215 20 16.8036V8M4 8V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H8M20 8V7.19691C20 6.07899 20 5.5192 19.7822 5.0918C19.5905 4.71547 19.2837 4.40973 18.9074 4.21799C18.4796 4 17.9203 4 16.8002 4H16M16 2V4M16 4H8M8 2V4" stroke="#6c6e79" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                        </svg>
                                        <span class="text-link">Ir a</span>
                                    </a>
                                </div>
                            </div>
                      </div>
                       
                    </div>

                </div>
               <!---- <div>
                    <mat-paginator [length]="events.length"
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    aria-label="Select page">
                    </mat-paginator>
                </div>-->
                

            </div>
               <!---- <mat-paginator [length]="events.length"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
                aria-label="Select page">
                </mat-paginator>--->

                <div *ngIf="error" class="message">
                    <p>{{msg}}</p>
                </div>
         
                <div class="buttons">
               </div>
     </div>
</div>
