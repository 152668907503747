
<div class="wrapper-calendar">
	<div class="header-calendar">
		<div>
			<button (click)="changeMonth(-1)" class="btn-prev">
				<img src="assets/img/arrow_back_ios_black_24dp.svg" />
				</button>
		</div>
		<h1>{{dateSelect | date:'MMMM, yyyy'}}</h1>
		<div>
			<button (click)="changeMonth(1)" class="btn-next">
				<img src="assets/img/arrow_forward_ios_black_24dp.svg" />
			</button>
		</div>
	</div>
	<ol>
		<li *ngFor="let day of week" class="day-name title">{{day | slice:0:3}}</li>

        <li (click)="clickDay(day)" [style.gridColumnStart]="first ? day?.indexWeek : 'auto'"
         *ngFor="let day of monthSelect;let first = first">
			<span *ngIf="!setDay"  [ngClass]="{'dayCurrent': this.daySelected==day.value , 'dayNoSelected' : this.daySelected!=day.value }">{{day?.value}}</span>
			<span *ngIf="setDay" [ngClass]="{'daySelected': valSel==day.value , 'dayNoSelected' : valSel!=day.value && (this.today!=day.value || this.today==day.value && this.monthCurrent!=this.monthChanged ),'dayCurrent': this.today==day.value && this.monthCurrent==this.monthChanged  }">{{day?.value}}</span>
		</li>
	</ol>
</div>

