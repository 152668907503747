import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { User } from "src/app/models/user";

export interface Calendar {
 
 idCalendar: any
 name: string;
 shortName: string;
 active:boolean
}

export interface Schedule {
    id: any;
    idWeekday: any
    day: string;
    from:any;
    to:any
}



@Injectable()
export class GlobalVariables {
 resourceSelected: any ='' 
 dateToEvent:any=''
 dateFromEvent:any=''
 hourToEvent: any=''
 hourFromEvent: any=''
 fecha=''
 searchText=''
 idWorkPlace: any=0
 startTimeWorkplace: string='08:00'
 urlimage:string=''
 hostname: string=''
 encryptSecretKey = "JA#AOO3O2G$8P2LK";
 user: User;
 role:any='';
 
 
 public resourcesArr: Array<any>=[]
 public arrHours: Array<any>=['06:00','06:15','06:30','06:45', '07:00','07:15','07:30','07:45', '08:00','08:15','08:30','08:45','09:00', '09:15','09:30', '09:45','10:00','10:15','10:30','10:45','11:00','11:15','11:30','11:45','12:00','12:15',
 '12:30','12:45','13:00','13:15','13:30','13:45','14:00','14:15','14:30','14:45','15:00','15:15', '15:30', '15:45', '16:00','16:15','16:30','16:45','17:00', '17:15',
 '17:30','17:45','18:00','18:15','18:30','18:45','19:00','19:15','19:30','19:45','20:00']
 colors: Array<any>= [
    {"id":"01", "color":"green", "assigned": false}, 
    {"id":"02", "color":"orange","assigned": false}, 
    {"id":"03", "color":"blue","assigned": false}, 
    {"id":"04", "color":"red","assigned": false}, 
    {"id":"05", "color":"purple","assigned": false}, 
    {"id":"06", "color":"#CD5C5C","assigned": false}, 
    {"id":"07", "color":"grey","assigned": false}, 
    {"id":"08", "color":"#FF5733","assigned": false}, 
    {"id":"09", "color":"#25DBD3","assigned": false}, 
    {"id":"10", "color":"#B845C8","assigned": false},
    {"id":"11", "color":"#9FC846","assigned": false},
  ]

  config={version:'20231130'}


  
    private calendarsByWorkPlace: Calendar[];
    private calendarsByWorkPlace$: Subject<Calendar[]>;
    private userNameLogged : string;
    private userNameLogged$: Subject<String>;
    private userLogged$: Subject<User>;
    private schedules: Schedule[];
    private schedules$: Subject<Schedule[]>

    constructor () {

        this.calendarsByWorkPlace=[];
        this.calendarsByWorkPlace$= new Subject();
        this.schedules=[];
        this.schedules$= new Subject();
        this.userLogged$= new Subject();
        this.userNameLogged$ = new Subject();
    
    }

     setresouserselected(val: any){
        this.resourceSelected= val
    }

     getresourceselected(){
        return this.resourceSelected;
    }

     setdateToEvent(val: any){
        this.dateToEvent= val
    }

     getdateToEvent(){
        return this.dateToEvent;
    }

     setdateFromEvent(val: any){
        this.dateFromEvent= val
    }

     getdateFromEvent(){
        return this.dateFromEvent;
    }

    sethourToEvent(val: any){
        this.hourToEvent= val
    }

     gethourToEvent(){
        return this.hourToEvent;
    }

     sethourFromEvent(val: any){
        this.hourFromEvent= val
    }

     gethourFromEvent(){
        return this.hourFromEvent;
    }

    setfecha(val: any){
        this.fecha= val
    }

     getfecha(){
        return this.fecha;
    }

    setresources(val: any){
        this.resourcesArr= val
    }

     getresources(){
        return this.resourcesArr;
    }

    setIdWorkPlace(val:any){
        this.idWorkPlace=val
    }

    getIdWorkPlace(){
        return this.idWorkPlace;
    }

    setstartTimeWorkplace(val: any)
    {
        this.startTimeWorkplace=val
    }

    getstartTimeWorkplace(){
        return this.startTimeWorkplace
    }

    setColorsCalendars(val: any)
    {
        this.colors=val
    }

    getColorsCalendars(){
        return this.colors
    }
    setSearchText(val: any)
    {
        this.searchText=val
    }

    getSearchtext(){
        return this.searchText
    }

    setUrlImage(val: any)
    {
        this.urlimage=val
    }

    getUrlImage(){
        return this.urlimage
    }

    setHostname(val: any)
    {
        this.hostname=val
    }

    getHostname(){
        return this.hostname
    }

    setNewcalendars(pCalendar: Calendar){
        this.calendarsByWorkPlace.push(pCalendar)
        this.calendarsByWorkPlace$.next(this.calendarsByWorkPlace)

    }

    getNewCalendars$(): Observable<Calendar[]>{
        return this.calendarsByWorkPlace$.asObservable();
    }

    setUserNameLogged(username:any)
    { 
        this.userNameLogged$.next(username)
    }

    getUserNameLogged$(): Observable<String>{
        return this.userNameLogged$.asObservable();
    }

    setSchedules(pschedule: Schedule){
        this.schedules.push(pschedule)
        this.schedules$.next(this.schedules)

    }

    getSchedules$(): Observable<Schedule[]>{
        return this.schedules$.asObservable();
    }

    clearNewCalendars()
    {
        this.calendarsByWorkPlace=[]
        this.calendarsByWorkPlace$.next(this.calendarsByWorkPlace)
    }

    setUserLogged(user:User)
    { 
        this.userLogged$.next(user)
    }

    getUserLogged$(): Observable<User>{
        return this.userLogged$.asObservable();
    }

    setRol(rol:any){
       this.role=rol
    }
       
    getRol()
    {
        return this.role
    }











  
}