import { ApiService } from './api.service';
import { Injectable,ViewContainerRef } from '@angular/core';
//import { Resource } from './base/resource';
import { ModalService } from './modal-service/modal-service.service';
import { Observable } from 'rxjs';





@Injectable()
export class UtilsService {


    constructor(
        api: ApiService,
        private modalService: ModalService,
     
    ) {
      //  super( api, 'utils');
    }

  

    openModalAddNewEvent(component: any, view: ViewContainerRef, title: any, description: any,startTimeWorkplace?: any, date?: any, end?: any, name?: any, resourceId?: any, arrNames?: Array<any> ,allDay?: any, idDetail?:any, resourceIds?:Array<any>, repeatParams?:any, currentDateRepeat?:any,extendedProps?:any,workPlaceName?: any)  {
       /* const options = {
            // title: 'El título de la modal',
            type: 'full',
            size: 'large',
            hideHeader: true,
            // miAtributoExtra: 'valor'
        };*/
       return  this.modalService.openModal( view, component, title, description,startTimeWorkplace, date,end, name, resourceId, arrNames, allDay, idDetail, resourceIds, repeatParams, currentDateRepeat, extendedProps, workPlaceName)
    

    }

    openModalShowMessage(component: any, showContainerRef: ViewContainerRef, message: string, action: string)  {
        /* const options = {
             // title: 'El título de la modal',
             type: 'full',
             size: 'large',
             hideHeader: true,
             // miAtributoExtra: 'valor'
         };*/
        return  this.modalService.openModalShowMessage( showContainerRef, component, message, action)
     
 
     }

     openModalSearchEvents(component: any, showContainerRef: ViewContainerRef, id: any, view)  {
    
        return  this.modalService.openModalSearchEvents( showContainerRef, component, id, view)
     
 
     }

     openModalSeriesEvents(component: any, seriesContainerRef: ViewContainerRef, action: any, messageA:any, messageB: any, messageC:any){
        return  this.modalService.openModalSeriesEvents( seriesContainerRef, component, action, messageA, messageB, messageC)

     }

     openModalShareEvents(component: any, shareEventsContainerRef: ViewContainerRef, htmlMessage: any, workPlaceName: string, phone:string){

      return this.modalService.openModalShareEvents(shareEventsContainerRef, component, htmlMessage, workPlaceName, phone)

     }



    
    
}
