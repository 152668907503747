import {  ViewContainerRef,ComponentRef, Type, Injectable } from '@angular/core'
import { Subject,  } from 'rxjs';
import {AddNewEventsComponent} from '../../resources-view-calendar/add-new-events/add-new-events.component'
import { ShowMessageComponent } from 'src/app/shared/modal/show_message/show-message.component';
import { SearchEventsComponent } from 'src/app/shared/modal/search-events/search-events.component';
import { SeriesEventsComponent } from 'src/app/shared/modal/series-events/series-events.component';
import { ShareEventsComponent } from 'src/app/shared/modal/share-events/share-events.component';

@Injectable({
    providedIn: 'root'
  })
export class ModalService {
    private componentRef!: ComponentRef<AddNewEventsComponent>;
    private messagecomponentRef!: ComponentRef<ShowMessageComponent>;
    private searchcomponentRef: ComponentRef<SearchEventsComponent>;
    private seriescomponentRef: ComponentRef<SeriesEventsComponent>
    private sharecomponentRef: ComponentRef<ShareEventsComponent>
    private componentSubscriber!: Subject<any>;
    private componentMessageSubscriber!: Subject<any>;
    private componentSearchSubscriber!: Subject<any>;
    private componentSeriesSubscriber!: Subject<any>
    private componentShareSubscriber!: Subject<any>
    constructor() {}
  
    openModal(entry: ViewContainerRef,component: Type<any>, modalTitle: string, description: string, startTimeWorkplace?: any , dateDefault?: any, endDate?:any, nameresourceId?:any, resourceId?:any, calendarsNamesArr?: Array<any>, allDay?:boolean, idDetail?: any , resourceIds?:Array<any>, repeatParams?:any, currentDateRepeat?:any, extendedProps?:any, workPlaceName?:any) {
     
      this.componentRef = entry.createComponent(component);
      this.componentRef.instance.workPlaceName = workPlaceName
      this.componentRef.instance.title = modalTitle;
      this.componentRef.instance.description = description;
      this.componentRef.instance.dateDefault= dateDefault;
      this.componentRef.instance.endDate= endDate;
      this.componentRef.instance.resourceId = resourceId;
      this.componentRef.instance.nameresourceId = nameresourceId;
      this.componentRef.instance.calendarsNamesArr = calendarsNamesArr;
      this.componentRef.instance.idDetail= idDetail,
      this.componentRef.instance.allDay= allDay,
      this.componentRef.instance.resourceIds= resourceIds,
      this.componentRef.instance.repeatParams=repeatParams,
      this.componentRef.instance.startTimeWorkplace=startTimeWorkplace
      this.componentRef.instance.currentDateRepeat= currentDateRepeat,
      this.componentRef.instance.extendedProps=extendedProps

      this.componentRef.instance.closeMeEvent.subscribe(() => this.closeModal());
      this.componentRef.instance.submitModal.subscribe((res) => {this.confirm(res)});
      this.componentRef.instance.deleteMeEvent.subscribe((res) =>{this.delete(res)})
      this.componentSubscriber = new Subject<any>();
      return this.componentSubscriber.asObservable();
    }

    openModalShowMessage(entry: ViewContainerRef,component: Type<any>, message: string, action: string){
      this.messagecomponentRef = entry.createComponent(component);
      this.messagecomponentRef.instance.message = message;
      this.messagecomponentRef.instance.action=action;
      this.messagecomponentRef.instance.closeMessageEvent.subscribe(() => this.closeMessageModal());
      this.messagecomponentRef.instance.cancelMessageEvent.subscribe((res) => {this.cancelMessage(res)});
      this.messagecomponentRef.instance.deleteMessageEvent.subscribe((res) =>{this.deleteMessage(res)});
      this.messagecomponentRef.instance.confirmMessageEvent.subscribe((res) =>{this.confirmEvent(res)});
      this.componentMessageSubscriber = new Subject<any>();
      return this.componentMessageSubscriber.asObservable();
    }

    openModalSearchEvents(entry: ViewContainerRef,component: Type<any>, id: any, view: any){
      this.searchcomponentRef = entry.createComponent(component);
      this.searchcomponentRef.instance.idWorkPlace = id;
      this.searchcomponentRef.instance.view = view;
      this.searchcomponentRef.instance.confirmMessageEvent.subscribe((res) => {this.confirmMessageEvent(res)})
      this.searchcomponentRef.instance.closeMessageEvent.subscribe((res) => {this.closeSearchEventsModal(res)});
      this.searchcomponentRef.instance.cancelMessageEvent.subscribe((res) => {this.cancelSearchEvents(res)});
      this.componentSearchSubscriber = new Subject<any>();
      return this.componentSearchSubscriber.asObservable();
    }

    openModalSeriesEvents(entry: ViewContainerRef,component: Type<any>,action:any, messageA: any, messageB:any, messageC:any){
      this.seriescomponentRef = entry.createComponent(component);
      this.seriescomponentRef.instance.typeAction=action;
      this.seriescomponentRef.instance.messageA=messageA;
      this.seriescomponentRef.instance.messageB= messageB;
      this.seriescomponentRef.instance.messageC= messageC;
      this.seriescomponentRef.instance.seriesConfirmEvent.subscribe((res) => {this.confirmSeriesEvents(res)})
      this.seriescomponentRef.instance.seriesCancelEvent.subscribe((res) => {this.cancelSeriesEvents(res)})
      this.seriescomponentRef.instance.seriesCloseEvent.subscribe((res) => {this.closeSeriesEvents(res)})
      this.componentSeriesSubscriber = new Subject<any>();
      return this.componentSeriesSubscriber.asObservable();
    }

       
    openModalShareEvents(entry: ViewContainerRef,component: Type<any>, htmlMessage, workPlaceName, phone){
      this.sharecomponentRef = entry.createComponent(component);
      this.componentShareSubscriber = new Subject<any>();
      this.sharecomponentRef.instance.htmlMessage=htmlMessage;
      this.sharecomponentRef.instance.phone=phone
      this.sharecomponentRef.instance.workPlaceName= workPlaceName;
      this.sharecomponentRef.instance.closeShareEvent.subscribe((res)=>{this.closeShareEvents(res)})
      return this.componentShareSubscriber.asObservable();
    }



    closeModal() {
      this.componentSubscriber.complete();
      this.componentRef.destroy();
    }
  
    confirm(value:any) {

      this.componentSubscriber.next(value);
      this.closeModal();
    }

    delete(value: any)
    { 
        this.componentSubscriber.next(value); 
        this.closeModal();
    }

    cancel(value: any)
    {this.componentSubscriber.next(value);
      this.closeModal();}

    closeMessageModal(){
        this.componentMessageSubscriber.complete();
      this.messagecomponentRef.destroy();
     }

    deleteMessage(value: any)
     {
         this.componentMessageSubscriber.next(value);
         this.closeMessageModal();
     }

    cancelMessage(value: any)
     {
      this.componentMessageSubscriber.next(value);
      this.componentMessageSubscriber.complete();
      this.messagecomponentRef.destroy();
      this.closeMessageModal();}

    confirmMessageEvent(value:any) {
        this.componentSubscriber.complete();
        this.componentRef.destroy();
        this.componentSubscriber.next(value);
        this.componentSearchSubscriber.complete();
      }
  

    closeSearchEventsModal(value: any){
      this.componentSearchSubscriber.next(value)
      this.componentSearchSubscriber.complete();
      this.searchcomponentRef.destroy();
     }

    cancelSearchEvents(value: any)
     {
      this.componentSearchSubscriber.next(value);
      this.componentSearchSubscriber.complete();
      this.searchcomponentRef.destroy();
      this.closeSearchEventsModal(value);}

    closeSeriesEvents(value: any){
        this.componentSeriesSubscriber.next(value)
        this.componentSeriesSubscriber.complete();
        this.seriescomponentRef.destroy();
    }

    cancelSeriesEvents(value: any){
      this.componentSeriesSubscriber.next(value);
      this.componentSeriesSubscriber.complete();
      this.seriescomponentRef.destroy()
    }

    confirmSeriesEvents(value: any){
      this.componentSeriesSubscriber.next(value);
      this.componentSeriesSubscriber.complete();
      this.seriescomponentRef.destroy()

    }

    confirmEvent(value:any){
      this.componentMessageSubscriber.next(value);
      this.componentMessageSubscriber.complete();
      this.messagecomponentRef.destroy()

    }

    confirmShare(value:any){
      this.componentShareSubscriber.next(value);
      this.componentShareSubscriber.complete();
      this.sharecomponentRef.destroy()
    }

    cancelShareEvents(value: any)
    {
     this.componentShareSubscriber.next(value);
     this.componentShareSubscriber.complete();
     this.sharecomponentRef.destroy();
     this.closeShareEvents(value);}

     closeShareEvents(value: any){
      this.componentShareSubscriber.next(value)
      this.componentShareSubscriber.complete();
      this.sharecomponentRef.destroy();
  }


       
}