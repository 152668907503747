<div class="user__page">
  <!--<section class="calendar-header">
    <div class="logo md-lg-mobile-desktop">
      <img class="logo-img" src="assets/img/clock_time_event_schedule_date_reminder_calendar_icon_225497.svg">
      <p class="title-logo">1Cal</p>
    </div>
    <div class="header-rest">
      <h1 class="title">
        Dashboard
      </h1>
    </div>

    <div class="search">
      <app-search (search)="handleSearch($event)"></app-search>
    </div>

    <div class="sprite-calendar-img-avatar-header">
      <a href="" class="logout">
        <img src="assets/img/logout-variant.svg">
      </a>
    </div>
  </section>-->


  <section class="desktop calendar-header">
    <div class="logo common-margins-logo"> 
      <div>
        <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
      </div>
      <div class="common-margins-title" >
        <span class="title-logo">1Cal</span>
      </div>
    </div>
    <div class="header-rest common-margins">
      <h1 class="title">
        Dashboard
      </h1>
    </div>
    <div class="search common-margins">
     <!---- <app-search [listFiltered]="workplaces" (search)="handleSearch($event)"></app-search>-->
     <div style="width:100%">
       <div class="container-1">
        <span class="image-search">
           <i style="background: url('assets/custom/{{imagepath}}/icono/icon-search.svg') no-repeat; width:38px">
          </i></span>
           <input id="Buscador" type="text" class="search" name="filterValue" placeholder="Buscar" [(ngModel)]="filterValue"  autocomplete="off"> 
        </div>
      </div>
    </div>
    <div class="user-avatar">
      <div class="sprite-calendar-img-avatar-header">
         <img src="assets/custom/{{imagepath}}/icono/logout.svg" (click)="clickExit($event)">
      </div>
    </div>
  </section>

  
  <section class="mobile calendar-header">
    <div class="calendar-header-sm-m">
       <div class="logo"> 
         <div class="logo-image-content">
           <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
         </div>
        <!---- <div class="common-margins">
           <span class="title-logo">1Cal</span>
         </div>-->
       </div>
       <div class="header-rest">
         <h1 class="title">
           Dashboard
         </h1>
       </div>
       <div class="search common-margins mobile-medium">
       <!----<app-search (search)="handleSearch($event)"></app-search>-->
         <div >
          <div class="container-1">
              <span class="image-search">
                <i style="background: url('assets/custom/{{imagepath}}/icono/icon-search.svg') no-repeat; width:38px">
                </i>
              </span>
              <input id="Buscador" type="text" class="search" name="filterValue" placeholder="Buscar" [(ngModel)]="filterValue" autocomplete="off">
           </div>
         </div>
      </div>
      <div class="user-avatar">
        <div class="sprite-calendar-img-avatar-header">
           <img src="assets/custom/{{imagepath}}/icono/logout.svg"  (click)="clickExit($event)">
        </div>
      </div>
     <!---- <div style="width:24px; height:24px" (click)="openAsideMenu($event)">
       <img class="menu-image" src="assets/img/icon-menu.svg"/>
     </div>
     <div id="backdrop" class="backdrop" style="display:none">
       <a href="#" id="close" style="display:none" class="closebtn " (click)="closeNav($event)">&times;
           <span>Menú</span>
       </a>
    </div>-->
    </div>
    <div class="search common-margins mobile-small">
     <!--<app-search (search)="handleSearch($event)"></app-search>-->
     <div>
        <div class="container-1">
          <span class="image-search">
            <i style="background: url('assets/custom/{{imagepath}}/icono/icon-search.svg') no-repeat; width:38px">
            </i>
          </span>
           <input id="Buscador" type="text" class="search" name="filterValue" placeholder="Buscar" [(ngModel)]="filterValue" autocomplete="off">
        </div>
     </div>
    </div>
  </section>

 <!---- <div>
    <div id="sideNavigation"  class="aside mobile" style="display:none">
        <app-aside-menu-mobile ></app-aside-menu-mobile>
    </div>
  </div>--->
  


  <section class="user__page-wrapper">
    <section class="user__page-content">
      <div class="user__page-dashboard" id="user__page-dashboard">
        <div class="calendar-box-wrapper">
          <div class="calendar-box-section">
            <a *ngFor="let workplace of workplaces | filter: filterValue" class="calendar-box-link">
              <div (click)="clickWorkPlace(workplace.idWorkPlace, workplace.name)" class="calendar-box-content">
                <div>
                  <mat-icon class="icon-menu" svgIcon="workshop"></mat-icon>
                </div>
                <div class="calendar-name">
                  <h4>{{workplace.name}}</h4>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </section>
  <router-outlet></router-outlet>
</div>
<router-outlet></router-outlet>